import { Layout } from '@layouts';
import axios from 'axios';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { createAccounts, insertContent } from '@queries';
import { TableLoader, Modal, Spinner } from '@components';
import { useSearchParams } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Dialog } from '@headlessui/react';
import baseUrl from '@root/config';
import _ from 'lodash';

function Validation() {
  const [searchParams] = useSearchParams();
  const [openValidate, setOpenValidate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(null);
  const [filters, setFilters] = useState([]);
  const [lines, setLines] = useState([]);

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedLines, setSelectedLines] = useState([]);
  const [search, setSearch] = useState('');

  const fileName = searchParams.get('fileName');

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/files/treat?fileName=${fileName}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenSAM')}`,
          'X-SAM': sessionStorage.getItem('tokenSAM'),
          'X-DMCC': sessionStorage.getItem('tokenDMCC')
        },
        onDownloadProgress: (progressEvent) => {
          try {
            setLines(
              JSON.parse('[' + progressEvent.event.currentTarget.response.slice(0, -1) + ']')
            );
          } catch (e) {
            console.log('Erreur lors du parsing : ', e);
          }
        }
      })
      .then(() => setIsLoading(false));
  }, []);

  const createAccountsMutation = useMutation({
    mutationFn: createAccounts,
    onSuccess: (dataReceived) => updateStatus(dataReceived)
  });
  const insertContentMutation = useMutation({
    mutationFn: insertContent,
    onSuccess: (dataReceived) => updateCreate(dataReceived)
  });

  const updateLineData = (id, key, value) =>
    setLines((prev) => {
      const index = prev.findIndex((l) => l.InternalId === id);
      return prev.toSpliced(index, 1, {
        ...prev[index],
        [key]: value
      });
    });

  useLayoutEffect(() => {
    const isIndeterminate = selectedLines.length > 0 && selectedLines.length < lines.length;

    setChecked(selectedLines.length === lines.length);
    setIndeterminate(isIndeterminate);

    if (!isLoading) checkbox.current.indeterminate = isIndeterminate;
  }, [selectedLines]);

  const updateStatus = (data) => {
    setLines((prev) =>
      prev.map((l) => {
        const match = data.find((d) => d.Account === l.Account);

        return {
          ...l,
          Status: match ? match?.status : l.Status,
          Id: match ? match?.id : l.Id
        };
      })
    );
  };

  const updateCreate = (data) => {
    setLines((prev) => {
      const dataToUpdate = data.filter(
        (l) =>
          Object.prototype.hasOwnProperty.call(l, '_title') &&
          ['success', 'error'].includes(l.status)
      );

      return prev.map((l) => {
        const match = dataToUpdate.find((d) => d._title === l?._title);

        return {
          ...l,
          status: match ? match?.status : l.status
        };
      });
    });
  };

  const missingsFilter = filters.includes('missing')
    ? lines.filter((l) => l.status === 'missing' || l.Status === 'missing')
    : lines;
  const errorsFilter = filters.includes('error')
    ? missingsFilter.filter((l) => l.status === 'error' || l.Status === 'error')
    : missingsFilter;
  const searchFilter =
    search.length > 0
      ? errorsFilter.filter(
          (l) =>
            l?.Id?.toLowerCase()?.includes(search?.toLowerCase()) ||
            l?.Name?.toLowerCase()?.includes(search?.toLowerCase()) ||
            l?.Type?.toLowerCase()?.includes(search?.toLowerCase()) ||
            l?.Description?.toLowerCase()?.includes(search?.toLowerCase()) ||
            l?.Date?.toLowerCase()?.includes(search?.toLowerCase()) ||
            l?.Credit?.toLowerCase()?.includes(search?.toLowerCase()) ||
            l?.Debit?.toLowerCase()?.includes(search?.toLowerCase()) ||
            l?.Company?.toLowerCase()?.includes(search?.toLowerCase())
        )
      : errorsFilter;

  const filteredLines = _.uniq(searchFilter.map((s) => s.GroupId))
    .map((v) => lines.filter((l) => l.GroupId === v))
    .flat();

  function toggleAll() {
    setSelectedLines(checked || indeterminate ? [] : filteredLines);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Validate the content</h1>
            <p className="mt-2 text-sm text-gray-700">
              Please make sure that the content corresponds before validating.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 flex gap-x-6">
            <button
              type="button"
              onClick={() => {
                // createAccountsMutation.mutate(lines.filter((l) => l.Status === 'missing'))
                const selectedGroupId = _.uniq(selectedLines.map((s) => s.GroupId));
                createAccountsMutation.mutate(
                  selectedGroupId.map((s) => lines.filter((l) => l.GroupId === s)).flat()
                );
              }}
              className="rounded-md bg-white px-3 py-2 text-center text-sm font-semibold text-black shadow-sm border border-black flex">
              {createAccountsMutation.isPending && <Spinner />}
              Create accounts
            </button>
            <button
              onClick={() => setOpenValidate(true)}
              type="button"
              className="flex rounded-md bg-black px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black">
              {insertContentMutation.isPending && <Spinner />}
              Insert content
            </button>
          </div>
        </div>
        <fieldset className="mt-8">
          <legend className="block font-medium">Filters</legend>
          <div className="space-x-6 pt-6 sm:space-x-4 sm:pt-4 flex">
            <div className="flex items-center text-base sm:text-sm">
              <input
                id={`category-missing`}
                name="category[]"
                type="checkbox"
                onClick={() =>
                  setFilters((prev) =>
                    prev.includes('missing')
                      ? prev.filter((v) => v != 'missing')
                      : [...prev, 'missing']
                  )
                }
                className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                defaultChecked={filters.includes('missing')}
              />
              <label htmlFor={`category-missing`} className="ml-3 min-w-0 flex-1 text-gray-600">
                Missing
              </label>
            </div>
            <div className="flex items-center text-base sm:text-sm">
              <input
                id={`category-error`}
                name="category[]"
                type="checkbox"
                className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                defaultChecked={filters.includes('error')}
                onClick={() =>
                  setFilters((prev) =>
                    prev.includes('error') ? prev.filter((v) => v != 'error') : [...prev, 'error']
                  )
                }
              />
              <label htmlFor={`category-error`} className="ml-3 min-w-0 flex-1 text-gray-600">
                Error
              </label>
            </div>
            <div className="flex items-center text-base sm:text-sm">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </fieldset>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Id
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Credit
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Debit
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Company
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Select</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredLines?.map((l, index) =>
                    Object.prototype.hasOwnProperty.call(l, '_title') ? (
                      <tr
                        className="border-t border-gray-200"
                        key={l._title + ' - ' + index.toString()}>
                        <th
                          colSpan={10}
                          scope="colgroup"
                          className={`py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 ${
                            {
                              missing: 'bg-yellow-100',
                              pending: 'bg-gray-50',
                              success: 'bg-green-50',
                              error: 'bg-red-50'
                            }[l.status]
                          }
                            `}>
                          {l._title}
                        </th>
                      </tr>
                    ) : Object.prototype.hasOwnProperty.call(l, 'OptionAccountType') ? (
                      <tr
                        key={l.Id}
                        className={` border-t border-gray-200 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 ${
                          {
                            missing: 'bg-yellow-100',
                            pending: 'bg-gray-50',
                            success: 'bg-green-50',
                            error: 'bg-red-50'
                          }[l.Status]
                        }`}>
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          {selectedLines.includes(l) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            value={index}
                            checked={selectedLines.includes(l)}
                            onChange={(e) =>
                              setSelectedLines(
                                e.target.checked
                                  ? [...selectedLines, l]
                                  : selectedLines.filter((p) => p !== l)
                              )
                            }
                          />
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                          {l.Id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                          {l.Account}
                        </td>
                        <td
                          colSpan={7}
                          scope="colgroup"
                          className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"></td>
                      </tr>
                    ) : (
                      <tr
                        key={`${index}`}
                        className={[
                          index === 0 ? 'border-gray-300 border-t' : 'border-gray-200 border-t',
                          {
                            missing: 'bg-yellow-100',
                            error: 'bg-red-100',
                            pending: ''
                          }[l.Status]
                        ].join(' ')}>
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          {selectedLines.includes(l) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            value={index}
                            checked={selectedLines.includes(l)}
                            onChange={(e) =>
                              setSelectedLines(
                                e.target.checked
                                  ? [...selectedLines, l]
                                  : selectedLines.filter((p) => p !== l)
                              )
                            }
                          />
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                          {l.Id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {l.Account}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {l.Type}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {l.Description}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {l.Date}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {l.Credit?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {l.Debit?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {l.Company}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                          <a
                            href="#"
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => setOpenUpdate(l?.InternalId)}>
                            Edit<span className="sr-only"></span>
                          </a>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              {isLoading && <TableLoader />}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={openUpdate != null} setIsOpen={() => setOpenUpdate(null)}>
        <div className="border-b border-gray-900/10 pb-12 px-4 pb-4 pt-5">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Line of journal entry</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Edit a current line of the journal entry.
          </p>

          <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label htmlFor="id" className="block text-sm font-medium leading-6 text-gray-900">
                ID
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="id"
                  id="id"
                  value={lines?.find((l) => l?.InternalId === openUpdate)?.Id}
                  onChange={(e) => updateLineData(openUpdate, 'Id', e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={lines?.find((l) => l?.InternalId === openUpdate)?.Account}
                  onChange={(e) => updateLineData(openUpdate, 'Account', e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="description"
                  id="description"
                  value={lines?.find((l) => l?.InternalId === openUpdate)?.Description}
                  onChange={(e) => updateLineData(openUpdate, 'Description', e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="credit" className="block text-sm font-medium leading-6 text-gray-900">
                Credit
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="credit"
                  id="credit"
                  value={lines?.find((l) => l?.InternalId === openUpdate)?.Credit}
                  onChange={(e) => updateLineData(openUpdate, 'Credit', e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="debit" className="block text-sm font-medium leading-6 text-gray-900">
                Debit
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="debit"
                  id="debit"
                  value={lines?.find((l) => l?.InternalId === openUpdate)?.Debit}
                  onChange={(e) => updateLineData(openUpdate, 'Debit', e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isOpen={openValidate} setIsOpen={setOpenValidate}>
        <div className="px-4 pb-4 pt-5">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                Are you sure to validate ?
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to proceed with the validation and the insertion of the
                  different data ?
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              onClick={() => {
                const selectedGroupId = _.uniq(selectedLines.map((s) => s.GroupId));
                insertContentMutation.mutate(
                  selectedGroupId.map((s) => lines.filter((l) => l.GroupId === s)).flat()
                );
                setOpenValidate(false);
              }}>
              Validate
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => setOpenValidate(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

export default Validation;
