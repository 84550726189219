import axios from 'axios';
import baseUrl from '@root/config';

export default async ({ request }) => {
  const url = new URL(request.url);

  try {
    const res = await axios.get(`${baseUrl}/api/auth/intuit/callback${url.search}`);
    if (res?.data?.jwt) sessionStorage.setItem(`token${res?.data?.company}`, res?.data?.jwt);
    window.location.replace('/');
  } catch (e) {
    window.location.replace('/error-auth');
  }

  return null;
};
