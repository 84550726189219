import { Layout } from '@layouts';
import baseUrl from '@root/config';
import { useNavigate } from 'react-router-dom';

function Token() {
  const navigate = useNavigate();

  const tokenDMCC = sessionStorage.getItem('tokenDMCC');
  const tokenSAM = sessionStorage.getItem('tokenSAM');

  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Connect with your QBO companies</h1>
            <p className="mt-2 text-sm text-gray-700">
              Please click on the following links to connect with the QBO companies.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 flex gap-x-6">
            <button
              onClick={() => navigate('/ftp')}
              type="button"
              disabled={!tokenDMCC || !tokenSAM}
              className="flex rounded-md bg-black px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black disabled:bg-gray-400 disabled:cursor-not-allowed">
              Continue
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Select</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      Levmet SAM
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${
                          tokenSAM
                            ? 'bg-green-50 text-green-700 ring-green-600/20'
                            : 'bg-red-50 text-red-700 ring-red-600/20'
                        }`}>
                        {tokenSAM ? 'Connected' : 'Disconnected'}
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a
                        href={`${baseUrl}/api/auth/intuit`}
                        className="text-indigo-600 hover:text-indigo-900"
                        rel="noreferrer">
                        Connect<span className="sr-only"></span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      Levmet Trading DMCC
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset  ${
                          tokenDMCC
                            ? 'bg-green-50 text-green-700 ring-green-600/20'
                            : 'bg-red-50 text-red-700 ring-red-600/20'
                        }`}>
                        {tokenDMCC ? 'Connected' : 'Disconnected'}
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a
                        href={`${baseUrl}/api/auth/intuit`}
                        className="text-indigo-600 hover:text-indigo-900"
                        rel="noreferrer">
                        Connect<span className="sr-only"></span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Token;
