import axios from 'axios';
import baseUrl from '@root/config';

const config = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('tokenSAM')}`,
    'X-SAM': sessionStorage.getItem('tokenSAM'),
    'X-DMCC': sessionStorage.getItem('tokenDMCC')
  }
};

export const getFiles = {
  queryKey: ['files'],
  queryFn: () => axios.get(`${baseUrl}/api/files/list`, config).then((data) => data.data)
};

export const treatFile = (name) => ({
  queryKey: ['files', name],
  queryFn: () =>
    axios.get(`${baseUrl}/api/files/treat?fileName=${name}`, config).then((res) => res.data),
  enabled: name.length > 0
});

export const createAccounts = (data) =>
  axios.post(`${baseUrl}/api/files/create`, data, config).then((res) => res.data);

export const insertContent = (data) =>
  axios.post(`${baseUrl}/api/files/insert`, data, config).then((res) => res.data);
