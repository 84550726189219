import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IntuitAuth } from '@context';

import { Ftp, Validation, Token } from '@pages';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false
    }
  }
});

const router = createBrowserRouter([
  { path: '/auth/intuit/local/callback', loader: IntuitAuth.intuitAuthCallback },
  { path: '/', loader: async () => redirect('/token') },
  { path: '/token', element: <Token /> },
  { path: '/ftp', element: <Ftp /> },
  { path: '/validation', element: <Validation /> }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
