import { Steps } from '@components';
import { useLocation } from 'react-router-dom';

const getIndex = (path) => ['/token', '/ftp', '/validation'].indexOf(path);

function Layout(props) {
  const { children = null } = props;
  const { pathname } = useLocation();

  const steps = [
    {
      id: 'Connect to QBO',
      name: 'Connect to the different companies',
      href: '/token',
      status: 0 - getIndex(pathname)
    },
    {
      id: 'Select a file',
      name: 'Choose a file from the FTP server',
      href: '/ftp',
      status: 1 - getIndex(pathname)
    },
    {
      id: 'Validation',
      name: 'Validate the information',
      href: '/validation',
      status: 2 - getIndex(pathname)
    }
  ];

  return (
    <div className="flex flex-col p-10 h-screen space-y-10">
      <Steps className="flex justify-center w-full" steps={steps} />
      <div className="h-full">{children}</div>
    </div>
  );
}

export default Layout;
